.mry__header {
    display: flex;
    flex-direction: row;
}

.mry__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20rem;
    margin-right: 16rem;
    -moz-animation: movefromleft 1.2s ;
    -webkit-animation: movefromleft 1.2s ;
    -ms-animation: movefromleft 1.2s ;
    animation: movefromleft 1.2s ;
}

.mry__header-content h1 {
    font-family: var(--font-family1);
    font-weight: 400;
    font-size: 56px;
    line-height: 75px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.mry__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    color: var(--color-text);
}

.mry__header-content__input {
    width: 100%;
    margin-top: 60px;

    display: flex;
    flex-direction: row;
}

.mry__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: var(--color-text);

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.mry__header-content__input button {
    width: 150px;
    min-height: 40px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    background: var(--color-button);
    border: 1px solid var(--color-button);
    padding: 0 1rem;
    color: var(--color-text);
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    text-transform: uppercase;
}
.mry__header-content__input button:hover {
    background: var(--color-button_hover);
    border: 1px solid var(--color-button_hover);
    transition: 0.2s all ease;
}

.mry__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.mry__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.mry__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: var(--color-text);
    text-align: center;
}

.mry__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mry__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1080px) {
    .mry__header {
        flex-direction: column;
    }

    .mry__header-content {
        margin: 0 0 3rem;
    }
    .mry__header-content__input button {
        width: 170px;
        min-height: 40px;
        font-size: 12px;
    }
    .mry__header-content {
        padding-right: 190px;
    }
}

@media screen and (max-width: 650px) {
    .mry__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .mry__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .mry__header-content__people {
        flex-direction: column;
    }

    .mry__header-content__people p {
        margin: 0;
    }

    .mry__header-content__input input,
    .mry__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .mry__header-content {
        padding-right: 10px;
    }
}

@media screen and (max-width: 390px) {
    .mry__header-content{
        margin: 10rem 0 0 0;
    }
    .mry__header-content h1 {
        font-size: 34px !important;
        line-height: 48px !important;
    }

    .mry__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .mry__header-content__input input,
    .mry__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
    .mry__header-content__input {
        justify-content: center;
        margin-top: 90px;
    }
    .q_nft:hover + .q_info {

        font-size: 12px;
        right: 38px;
        background: #131212;
        width: 280px;
        height: 260px;
    }
    .mry__header-content {
        padding-right: 0px;
    }
}
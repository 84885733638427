.mry__btn button {
    width: 130px;
    min-height: 35px;
    font-family: var(--font-family);
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    background: var(--color-button);
    padding: 0 1rem;
    color: var(--color-text);
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: 2px;
}
.mry__btn button:hover {
    background: var(--color-button_hover);
    transition: 0.2s all ease;
}
.mry__contact {
    display: flex;
    flex-direction: row;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    border-radius: 5px;
    margin: 3rem 0rem 0rem;
}

.mry__pcontact-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.mry__contact-image img {
height: 100%;
width: 90%;
}

.mry__contact-content {
    flex: 52%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 65px 100px 0px 0px;
    gap: 30px;
}
input[type=text], select, textarea {
    width: 100%;
    padding: 22px;
    border: 1px solid #080808;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background: #080808;
    color: #fff;
    font-size: 14px;

  }
  textarea {
   min-height: 150px;
   font-family: var(--font-family);
  }
  input:focus-visible,   textarea:focus-visible  {
    border: 1px solid var(--color-link);
}
textarea:focus, input:focus{
    outline: none;
}
::-webkit-input-placeholder { 
    text-transform: uppercase;
    font-size: 14px;
  }
  
  :-ms-input-placeholder { 
    text-transform: uppercase;
    font-size: 14px;
  }
  
  ::placeholder {
    text-transform: uppercase;
    font-size: 14px;
  }

@media screen and (max-width: 950px) {
    .mry__contact {
        flex-direction: column;
    }

    .mry__contact-image {
        margin: 1rem 0;
        display: none;
    }

    .mry__contact-image img {
        width: unset;
        height: unset;
    }

    .mry__contact-content {
        margin-top: 2rem;
        padding: 25px;
        gap: 15px;
    }
}

@media screen and (max-width: 700px) {
    .mry__contact-image img {
        width: 100%;
        height: 100%;
    }
    input[type=text], select, textarea {
        padding: 20px;
    }
}
@media screen and (max-width: 490px) {
    .mry__contact-image img {
        width: 100%;
        height: 100%;
    }
    input[type=text], select, textarea {
        padding: 15px;
    }
    .mry__contact-content {
        padding: 20px 34px !important;
        gap: 6px;
    }
    .mry__contact-content .mry__btn {
        margin: auto !important;
    }
    input::placeholder, textarea::placeholder {
        font-size: 12px;
    }
}



.mry__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    margin: 30px 65px;
    border-radius: 5px;
    font-family: var(--font-family);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}
.mry__footer a:hover{
    color: var(--color-link)
}
.mry__footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.mry__footer-privacy{
    display: flex;
    gap: 20px;

}
@media screen and (max-width: 850px) {
    .mry__footer {
        flex-direction: column;
        margin: 30px 32px;
        padding: 20px;
    }
    .mry__footer.mry__footer-links.mry__social {
        display: flex !important;
    }
}

@media screen and (max-width: 550px) {
    .mry__footer {
        flex-direction: column;
        margin: 30px 32px;
        padding: 20px;
    }
    .mry__footer.mry__footer-links.mry__social {
        display: flex !important;
    }
}

@media screen and (max-width: 490px) {
    .mry__footer {
        flex-direction: column;
        margin: 30px 32px;
        padding: 20px;
        
    }
    .mry__footer p{
        font-size: 12px !important;
        padding-bottom: 10px;
    }
    .mry__footer.mry__footer-links.mry__social {
        display: flex !important;
    }
    .mry__footer-privacy {
        font-size: 12px !important;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@font-face {
  font-family: 'Monument';
  src: url("./fonts/Monument/MonumentExtended-Regular 2.otf") format("otf")
}
@import url('http://fonts.cdnfonts.com/css/monument-extended');

:root {
  --font-family: 'Roboto', sans-serif;
  --font-family1: 'Monument Extended', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #FFF 1.84%, #FFF 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #FF5920 -13.86%, #F49867 99.55%);
  
  --color-bg: #0A0A0A;
  --color-footer : #18171A;
  --color-blog: #0A0A0A;
  --color-text: #FFF;
  --color-subtext: #B4B4B4;
  --color-link: #c53301;
  --color-button: #c53301;
  --color-textbutton: #FFF;
  --color-mark: #c53301;
  --color-markg: #c53301;
  --color-hover: #c53301;
  --color-button_hover: #641a00;
}

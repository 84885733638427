.mry__container_mark{
    width: 12px;
    height: 12px;
    background: var(--color-mark);
    border-radius: 50%;
}
.mry__container_markg{
    width: 12px;
    height: 12px;
    background: var(--color-markg);
    border-radius: 50%;
}
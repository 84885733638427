.mry__porfolio {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    margin: 3rem 0rem;
}
.porfolio_div{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(243px, auto);
    margin-bottom: 24px;
    gap: 24px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

}
.porfolio_group{
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.porfolio_group img{
    filter: grayscale(100%);
    width: 26%;
}
.porfolio_group:hover img{
    filter: grayscale(0%);
}
.porfolio_group svg{
    width: 240px;
}
.porfolio_group p{

}
.porfolio_group.end_block{

}
.porfolio_divB{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.porfolio_info {
    display: flex;
    padding: 34px;
    gap: 15px;
    align-items: center;
    border: 1px solid rgb(152 152 152 / 30%);
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
}
@media screen and (max-width: 850px) {
    .porfolio_div{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(160px, auto);
    }
    .porfolio_group img {
        width: 40%;
    }
}

@media screen and (max-width: 550px) {
    .porfolio_div{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(160px, auto);
    }
    .porfolio_group img {
        width: 40%;
    }
}


@media screen and (max-width: 490px) {

    .porfolio_div{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(160px, auto);
    }
    .porfolio_group img {
        width: 33%;
    }
    .porfolio_div {
        grid-auto-rows: minmax(155px, auto) !important;
    }
    .porfolio_div {
        gap: 15px !important;
    }
}
@media screen and (max-width: 375px) {

    .porfolio_div {
        grid-auto-rows: minmax(140px, auto) !important;
    }
}

.mry__blog-container_block{
    display: flex;
    align-items: center;
    gap: 15px;
    width: 180px;
    height: 49px;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    border-radius: 5px;
    padding: 0px 24px;
}
.mry__blog-container_mark{
    width: 18px;
    height: 18px;
    background: var(--color-mark);
    border-radius: 50%;
}
.mry__blog-container_block-content p{
    font-family: var(--font-family)!important;
    font-size: 12px !important;
    color: var(--color-text);
    text-transform: uppercase;
}
.mry__brand{
    margin-top: 40px;
}
.mry__brand h3{
    padding-bottom: 30px;
    -moz-animation: movefromright 1.2s ;
    -webkit-animation: movefromright 1.2s ;
    -ms-animation: movefromright 1.2s ;
    animation: movefromright 1.2s ;
}
.mry__brand .text_about p{
    font-family: var(--font-family);
    font-size: 17px;
    color: #fff;
    padding-bottom: 20px;
    line-height: 28px;
    -moz-animation: movefrombottom 1s ;
    -webkit-animation: movefrombottom 1s ;
    -ms-animation: movefrombottom 1s ;
    animation: movefrombottom 1s ;
}

.text_about{
    font-family: var(--font-family);
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
    text-align: justify;
    color: #fff;
    margin: 100px 00px 60px;
}
.ps_about{
    display: flex;
    margin: 10px 0px;
    gap: 160px;
}
.ps_block{
    display: flex;
    flex: 1;
    gap: 15px;
    align-items: baseline;
}
.ps_block p{ 
    font-family: var(--font-family);
    color: #fff;
    font-size: 14px;}

@media screen and (max-width: 850px) {
    .ps_about {
        gap: 20px;
        flex-direction: column;
    }
    .ps_block{
        line-height: 26px;
    }
    .text_about {
        margin: 120px 0px 100px;
    }
}

@media screen and (max-width: 550px) {
    .ps_about {
        gap: 20px;
        flex-direction: column;
    }
    .ps_block{
        line-height: 26px;
    }
    .text_about {
        margin: 120px 0px 100px;
    }

}

@media screen and (max-width: 490px) {

    .ps_about {
        gap: 20px;
        flex-direction: column;
    }
    .ps_block{
        line-height: 26px;
        font-size: 12px !important;
    }
    .text_about {
        margin: 80px 0px 60px;
    }
    .text_about {
        font-size: 19px !important;
    }
    .mry__brand .text_about p {
        font-size: 16px !important;;
    }
}


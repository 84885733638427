
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg);
}
#root{
  background-image: url('assets/bg_cat1.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 44%;
}
.App{
  max-width: 1440px;
  margin: auto;
}
a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  min-height: 100vh;
  
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 5rem 4rem;
}
.mt7{
  margin-top: 7rem;
}
.mb7{
  margin-bottom: 7rem;

}
.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes movefromtop {
  from {
      transform : scale(0);
      opacity   : 0;
      transform : translateY(-1em)
  }
  to {
      transform : scale(1);
      opacity   : 1;
      transform : translateY(0em)
      
  }
}
@keyframes movefromleft {
  from {
      transform : scale(0);
      opacity   : 0;
      transform : translateX(-10em)
  }
  to {
      transform : scale(1);
      opacity   : 1;
      transform : translateX(0em)
      
  }
}
@keyframes movefromright {
  from {
      transform : scale(0);
      opacity   : 0;
      transform : translateX(10em)
  }
  to {
      transform : scale(1);
      opacity   : 1;
      transform : translateX(0em)
      
  }
}
@keyframes movefrombottom {
  from {
      transform : scale(0);
      opacity   : 0;
      transform : translateY(5em)
  }
  to {
      transform : scale(1);
      opacity   : 1;
      transform : translateY(0em)
      
  }
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-moz-keyframes overlay-hide {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-webkit-keyframes overlay-hide {
  0% {
    opacity: 1;
    z-index: 100000;
  }
  15% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes overlay-hide {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 1;
 
  }
}

@keyframes overlay-hide {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }
  99% {
    opacity: 1;
 
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1440px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .App{
    max-width: 1140px;
    margin: auto;
  }
  .mry__navbar {
    padding: 2rem;
  }
  .mry__header-content {
    margin-top: 10rem;
  }
  .mry__header-content h1 {
    font-size: 44px;
    line-height: 64px;
  }
  .text_about {
    font-size: 24px;
  }
  .mry__brand p {
    font-size: 16px;
    line-height: 26px;
  }
  .community_divA{
    gap: 12px;
  }
  .community_group {
    padding: 30px 26px;
}
  .community_group p {
    font-size: 12px;
    line-height: 20px;
  }
  .community_group img {
    width: 60px;
    margin-bottom: 10px;
  }
  .porfolio_div {
    grid-auto-rows: minmax(196px, auto);
  }
  .mry__contact-content {
    padding: 60px 100px 0px 0px;
    gap: 6px;
  }
  .mry__footer{
    margin: 0.5rem 2rem;
  }
  .community_info {
    font-size: 16px;
}
.info_group {
  left: 70%;
  font-size: 12px;
  text-transform: none;
  height: 100px;
  width: 300px;
}
.mry__navbar-links_container a {
  padding: 20px 14px;
}
.mry__features-container_nft-text p {

  font-size: 13px;
  line-height: 24px;
}
.mry__features-container__nft {
  padding: 4px 0px;
}
}
@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
  #root{
    background-position: 150% 60px;
    background-size: 76%;
  }
  
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 2rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
  #root{
    background-position: 150% 60px;
    background-size: 76%;

  }
}
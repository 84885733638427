.mry__blog-container_article {
    width: 100%;
    display: flex;
    flex-direction: column;
    
}

.mry__blog-container_article-image {
    height: 100%;
    margin: auto;
}
.mry__blog-container_article-image img {
    width: 100%;
}

.mry__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0rem;
    height: 100%;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    padding: 40px 50px;
    border-radius: 5px;
    min-height: 480px;
}
.mry__blog-container_article-content .mry__btn{
    justify-content: center;
    display: flex;
  
}
.mry__blog-container_article-content h4 {
    font-family: var(--font-family);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #333;
    margin-bottom: 29px;
    padding-bottom: 22px;
}

.mry__blog-container_article h3 {
    font-family: var(--font-family1);
    text-transform: uppercase;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    margin-top: 2rem;
}

.mry__blog-container_article-content p:last-child {
}

img.img_cover {
    width: 100%;
    height: 100%;
    objectFit: cover;
    -webkit-transition: .7s ease-in-out;
  -moz-transition: .7s ease-in-out;
  -o-transition: .7s ease-in-out;
  transition: .7s ease-in-out;
}
.q_nft_block{
    position: relative;
    font-family: var(--font-family);
    color: #ccc;
    display: flex;
    justify-content: right;
    margin-right: -20px;
    margin-top: -15px;
}
.price{
    font-family: var(--font-family);
    color: #fff;
    font-size: 22px;
    text-align: center;

}

.q_nft {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 20px;
    margin: -20px;
  }
  .q_nft:hover {
    color: var(--color-link);
  }
.q_nft .q_info {
    visibility: hidden;
    width: 466px;
    font-size: 13px;
    line-height: 20px;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    color: #fff;
    text-align: center;
    padding: 20px 15px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 127%;
    right: -9px;
    opacity: 0;
    transition: opacity 0.4s;
    box-shadow: 0px 10px 15px #000;
  }
  
  .q_nft .q_info::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  
  .q_nft:hover .q_info {
    visibility: visible;
    opacity: 1;
  }
@media screen and (max-width: 1440px) {
    .q_nft .q_info {
        width: 320px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
    .mry__blog-container_article-content {
        min-height: 440px;
    }
}
@media screen and (max-width: 1280px) {
    .q_nft .q_info {
        width: 320px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
}
@media screen and (max-width: 1080px) {
    .q_nft .q_info {
        width: 295px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
}
@media screen and (max-width: 414px) {
    .q_nft .q_info {
        width: 325px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
}
@media screen and (max-width: 390px) {
    .mry__blog-container_article-content h3 {
        font-family: var(--font-family1);
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        line-height: 25p;
    }
    .mry__blog-container {
        gap: 20px !important;
    }
    .mry__blog-container_article-content h4 {
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 35px;
    }
    .q_nft {
        margin-bottom: -40px;
    }
    .q_nft .q_info {
        width: 336px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
    .q_nft_block {
        margin-right: -20px;
        margin-top: -20px;
        margin-bottom: 14px;
    }
    .mry__blog-container_article-content {
        padding: 40px 35px;
    }
}

@media screen and (max-width: 375px) {
    .q_nft .q_info {
        width: 320px;
        font-size: 13px;
        padding: 20px 15px;
        right: 0px;
    }
}
@media screen and (max-width: 360px) {
    .q_nft .q_info {
        width: 300px;
        right: 2px;
    }
}
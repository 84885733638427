.mry__blog {
    display: flex;
    flex-direction: column; 
    
}
.moveleft{
    -moz-animation: movefromleft 1s ;
    -webkit-animation: movefromleft 1s ;
    -ms-animation: movefromleft 1s ;
    animation: movefromleft 1s ;
}
.moveright{
    -moz-animation: movefromright 1s ;
    -webkit-animation: movefromright 1s ;
    -ms-animation: movefromright 1s ;
    animation: movefromright 1s ;
}
.mry__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.mry__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.mry__blog-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    gap: 80px;
    padding: 0px 150px;
}

.mry__blog-container_groupA {
    flex: 1;
    padding: 0 100px;
}

.last-itemA {
    margin-top: 176px!important;
  }
.last-itemB {
    margin-top: 95px!important;
  }
.mry__blog-container_groupB {
    flex: 1;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .mry__blog-container {
        flex-direction: column;
        padding: 0px;
    }


    .mry__blog-container_groupA {
        margin: 2rem 0;
    }

    .mry__blog-container_groupA .mry__blog-container_article  {
       width: 48%;
    }

    .mry__blog-container_groupA .mry__blog-container_article-image {
        
    }
}

@media screen and (max-width: 700px) {
    .mry__blog-container_group {
        grid-template-columns: repeat(1, 1fr);
    }

    .mry__blog-container_groupA .mry__blog-container_article  {
       width: 100%;
    }
    .mry__blog-container_groupA {
	padding:0;}
    .mry__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .mry__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }


}

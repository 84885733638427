.mry__social{
   
}
.mry__social-links{
    display: flex;
   gap: 15px;
   align-items: center;
   justify-content: space-between;
}
.social_icons a {
    align-items: center;
    display: flex;
}
.social_icons a svg {
    fill: var(--color-text);
}
.social_icons:hover a svg {
    cursor: pointer;
    fill: var(--color-link);
    transition: 0.3s all ease;
  
}
@media screen and (max-width: 850px) {
    .mry__social {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .mry__social {
        display: none;
    }
}

@media screen and (max-width: 400px) {

    .mry__social {
        display: none;
    }
}

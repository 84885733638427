.mry__community {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-family: var(--font-family);
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    gap: 80px;
    margin: 3rem 0rem;
}
.community_divA{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

}
.community_group{
    flex: 48%;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    border-radius: 5px;
    padding: 34px 40px;

}
.community_group img{
    width: 65px;
    margin-bottom: 15px;
}
.community_group svg{
    width: 240px;
}
.community_group p{
    line-height: 18px;
}
.community_group.end_block{

}
.community_divB{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.community_info {
    display: flex;
    padding: 16px;
    gap: 15px;
    align-items: center;
    border: 1px solid rgb(152 152 152 / 30%);
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    height: 100%;
}
.community_info:hover{
    cursor: pointer;
}
.community_info:hover .mry__container_markg{
    background: rgb(152 152 152 / 30%);
}

.info_group {
    position: absolute;
    left: 66%;
    display: none;
    padding: 22px;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    border-radius: 5px;
    -webkit-transition:all 0.1s ease-in;
    -moz-transition:all 0.1s ease-in;
    transition:all 0.1s ease-in;
    font-size: 13px;
    text-transform: none;
    height: 100px;
    width: 380px;
    line-height:16px;
    
}
.community_info:hover span.info_group {
    display: flex;
    align-items: center;
}
.tooltip {
    position: relative;
    display: inline-block;
    min-width: 150px;
  }

.tooltip .tooltiptext {
    visibility: hidden;
    width: 350px;
    font-size: 11px;
    line-height: 18px;
    background-image: url('../../assets/bg_blocks_b.png');
    background-color: var(--color-footer);
    color: #fff;
    text-align: center;
    padding: 20px 15px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 285%;
    left: 70%;
    opacity: 0;
    transition: opacity 0.4s;
    box-shadow: 0px 10px 15px #000;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: var(--color-link) transparent transparent transparent;
  }
  
  .community_info:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

@media screen and (max-width: 1440px) {
    .tooltip .tooltiptext {
        width: 320px;
        font-size: 10px;
        bottom: 310%;
        left: 35%;
    }
}
@media screen and (max-width: 1280px) {
    .tooltip .tooltiptext {
        width: 320px;
        font-size: 10px;
        bottom: 310%;
        left: 35%;
    }
}
@media screen and (max-width: 1080px) {
    .tooltip .tooltiptext {
        width: 320px;
        font-size: 10px;
        bottom: 310%;
        left: 26%;
    }
}
@media screen and (max-width: 850px) {
    .mry__community{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .community_group img {
        width: 75px;
    }
}

@media screen and (max-width: 550px) {
    .mry__community{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .community_group img {
        width: 75px;
    }

}
@media screen and (max-width: 414px) {
    .tooltip .tooltiptext {
        left: -19%;
        bottom: 250%;
    }
}
@media screen and (max-width: 390px) {

    .mry__community{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .community_group img {
        width: 45px !important;
    }
    .community_group p {
        font-size: 11px !important;
        line-height: 17px !important;
    }
    .community_group {
        padding: 25px 20px !important;
    }
    .community_divB {
        gap: 15px !important;
    }
    .community_info {
        padding: 20px;
    }
    .mry__community{
        gap: 50px !important;
    }
    .info_group {
        position: absolute;
        left: 48% !important;
        font-size: 12px !important;
        height: 140px !important;
        width: 200px !important;
        line-height: 18px !important;
       
    }
    .tooltip .tooltiptext {
        width: 270px;
        font-size: 10px;
        bottom: 285%;
        left: -12%;
    }
}

@media screen and (max-width: 375px) {
    .tooltip .tooltiptext {
        left: -18%;
    }
}
@media screen and (max-width: 360px) {
    .tooltip .tooltiptext {
        left: -23%;
    }
}
.mry__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    -webkit-animation : movefromtop 1s ;
    -moz-animation    : movefromtop 1s ;
    -o-animation      : movefromtop 1s ;
    animation         : movefromtop 1s ;
}

.mry__navbar-links {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mry__navbar-links_logo {
    margin-right: 2rem;
}

.mry__navbar-links_logo img {
    width: 134px;
    height: 45px;
}

.mry__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content:center;
}
.mry__navbar-links_container a {
    padding: 20px;
}

.mry__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mry__navbar-links_container p,
.mry__navbar-sign p,
.mry__navbar-menu_container p {
    color: var(--color-textbutton);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
}
.mry__navbar-links_container p:hover,
.mry__navbar-sign p:hover,
.mry__navbar-menu_container p:hover {
    color: var(--color-link);
    transition: 0.2s all ease;
}

.mry__navbar-sign button,
.mry__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: var(--color-textbutton);
    background: var(--color-button);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s all ease;
}

.mry__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.mry__navbar-menu svg {
    cursor: pointer;
}

.mry__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.mry__navbar-menu_container p {
    margin: 1rem 0;
}

.mry__navbar-menu_container-links-sign {
    display: none;
}



.block_lang_ico{
   
}

.block_soc{
    display: flex;
    gap: 30px;
}
.btn_lang{
    font-size: 16px;
    font-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50px;
    cursor: pointer;
    color: var(--color-textbutton);
    font-family: var(--font-family);
    background: none;
    border: none;
}
.btn_lang:hover{
    color: var(--color-link);
    transition: 0.2s all ease;
}
@media screen and (max-width: 1050px) {
    .mry__navbar-links_container {
        display: none;
    }

    .mry__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .mry__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .mry__navbar {
        padding: 2rem;
    }

    .mry__navbar-sign {
        display: none;
    }

    .mry__navbar-menu_container {
        top: 20px;
    }

    .mry__navbar-menu_container-links-sign {
        display: block;
    }
}

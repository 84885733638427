.mry__features-container__nft {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px 0px;
    gap: 12px;
}

.mry__features-container_nft-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
}


@media screen and (max-width: 490px) {

    .mry__features-container_nft-text p {
        font-size: 11px;
        line-height: 20px;
    }

    .mry__features-container__nft {
        margin: 0;
    }
}